// External Dependencies
import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

// Local Dependencies
import HeaderBrand from './HeaderBrand';

// Local Variables
const propTypes = {
  hideLogo: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

const defaultProps = {
  hideLogo: false,
  subtitle: null,
  title: null,
};

// Component Definition
const HeaderGeneric = ({
  hideLogo,
  subtitle,
  title,
}) => (
  <header
    className="generic-header"
    id="header"
  >
    <div className="link-wrapper">
      <Link to="/">
        <i className="fa fa-long-arrow-left" aria-hidden="true" /> Home
      </Link>
    </div>

    {!hideLogo && <HeaderBrand />}

    {title && <h2 className="title">{title}</h2>}
    {subtitle && <p>{subtitle}</p>}
  </header>
);

HeaderGeneric.propTypes = propTypes;
HeaderGeneric.defaultProps = defaultProps;

export default HeaderGeneric;
