// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Component Definition
const QAndA = () => (
  <Layout hideFooter>
    <Meta title="Question Success" />
    <HeaderGeneric
      title="Question submitted!"
    />

    <div className="flex-with-gap">
      <Link
        className="button special-2"
        to="/live_questions"
      >
        New Question
      </Link>

      <Link
        to="/"
      >
        Home
      </Link>
    </div>
  </Layout>
);

export default QAndA;
