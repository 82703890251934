// External Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Internal Dependencies
import PrestoLogoWhiteText from './shared/PrestoLogoTextWhite.svg';
import PrestoLogoWhite from './shared/PrestoLogoWhite.svg';

// Component Definition
const HeaderBrand = ({
  isMainPage = false,
}) => (
  <>
    <span className={isMainPage ? 'logo' : 'logo logo-small'}>
      <PrestoLogoWhite />
    </span>
    <h1 className={isMainPage ? 'logo-text' : 'logo-text logo-text-small'}>
      <PrestoLogoWhiteText />
    </h1>

    {isMainPage && (
      <h2>The Fine Arts Management Magician</h2>
    )}
  </>
);

HeaderBrand.propTypes = {
  isMainPage: PropTypes.bool,
};

export default HeaderBrand;
